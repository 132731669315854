import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddProduct = () => {
  const navigate = useNavigate()
  const [disabled,setDisabled] = useState(false)

  const [file0, setFile0] = useState();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();

  const [images, setImages] = useState([])
  const [title,setTitle] = useState('')
  const [cost,setCost] = useState('')
  const [wholesale,setWholesale] = useState('')
  const [offer,setOffer] = useState('')
  const [retail,setRetail] = useState('')
  const [quantity,setQuantity] = useState('')
  const [category,setCategory] = useState('')
  const [link,setLink] = useState('')
  const [description,setDescription] = useState('')
  
  const submit = async (e) => {
    
    
    try{
      if(title && cost && wholesale && offer && retail && quantity && category && description){
        setDisabled(true)
        
        if( (cost>0 && cost<999999) && (wholesale>0 && wholesale<999999) && (offer>0 && offer<999999) && (retail>0 && retail<999999) && (quantity>0 && quantity<999999) ){
          
          if(images.length===0){setDisabled(false);alert('Please Add Photos')}
          else if(images.length>3){setDisabled(false);alert('Maximum 3 Photos');setImages([])}
          else{
            let url = 'https://api.zakhira.askfsd.com/addProduct'
            
            const stock = true
            const formData = new FormData()
            for (let i = 0; i < images.length; i++) {
              formData.append("images", images[i]);
            }
            formData.append('title',title)
            formData.append('stock',stock)
            formData.append('cost',cost)
            formData.append('wholesale',wholesale)
            formData.append('offer',offer)
            formData.append('retail',retail)
            formData.append('quantity',quantity)
            formData.append('category',category)
            formData.append('link',link)
            formData.append('description',description)
            
            let result = await axios.post(url,formData)
            
            if(result.data.message){
              alert(result.data.message)
              navigate('/productList')
            }
            
          }
        }
      
        else{
          setDisabled(false)
          alert("Please Enter Correct Price")
        }

      }
      else{alert("Please All Fields")}

    }
    catch{
      setDisabled(false)
      alert("Error")
    }
  }
  
  const handleChange = (e) => {
      let file =(e.target.files);
      if(images.length===0){
        if(file[0]){ setFile0(URL.createObjectURL(file[0])) }
        if(file[1]){ setFile1(URL.createObjectURL(file[1])) }
        if(file[2]){ setFile2(URL.createObjectURL(file[2])) }
        setImages([...images, ...file])
      }
      else if(images.length===1){
        if(file[0]){ setFile1(URL.createObjectURL(file[0])) }
        if(file[1]){ setFile2(URL.createObjectURL(file[1])) }
        setImages([...images, ...file])
      }
      else if(images.length===2){
        if(file[0]){ setFile2(URL.createObjectURL(file[0])) }
        setImages([...images, ...file])
      }
      else{
        setFile0(); setFile1(); setFile2()
        alert('Maximum 3 Photos')
        setImages([])
      }
    }

  return (
    <div className='container mb-5 body'>
      <h2 className='text-primary mt-4'>Add Product</h2>
      
      <div className='row justify-content-evenly mt-5'>
        <div className='col-2'><img className='rounded preview' src={file0} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={file1} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={file2} alt=''/></div>
      </div>
      
      <div className="row justify-content-evenly">
        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Images</b></label>
          <input type="file" className="form-control" accept='image/*' multiple name="images" onChange={handleChange} />
        </div>

        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Title</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Product Title" name="title"  
          onChange={(e)=>setTitle(e.target.value)} />
        </div>
      </div>
      
      <div className="row justify-content-evenly">
        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Cost Price</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Cost Price" name="cost"  
          onChange={(e)=>setCost(e.target.value)} />
        </div>
      
        <div className="col-10 col-md-6 col-lg-4 mt-4">
        <label className='p-2'><b>Wholesale Price</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Wholesale Price" name="wholesale"  
          onChange={(e)=>setWholesale(e.target.value)} />
        </div>
      </div>
      
      <div className="row justify-content-evenly">
        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Offer Price</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Offer Price" name="offer"  
          onChange={(e)=>setOffer(e.target.value)} />
        </div>
      
        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Retail Price</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Retail Price" name="retail"  
          onChange={(e)=>setRetail(e.target.value)} />
        </div>
      </div>

      <div className="row justify-content-evenly">
        <div className="col-10 col-md-6 col-lg-4 mt-4">
        <label className='p-2'><b>Quantity</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Quantity" name="quantity"  
          onChange={(e)=>setQuantity(e.target.value)} />
        </div>

        <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Category</b></label>
          <select className="form-control text-center" name="category"
           onChange={(e)=>setCategory(e.target.value)} value={category}>
            <option value="" disabled hidden>Select a Category</option>
            <option value="Wallets">Wallets</option>
            <option value="Belts">Belts</option>
            <option value="Watches">Watches</option>
            <option value="Bags">Bags</option>
            <option value="Accessories">Accessories</option>
            <option value="Shoes">Shoes</option>
            <option value="Sunglasses">Sunglasses</option>
          </select>
        </div>
      </div>

      <div className="row justify-content-evenly">
        <div className="col-9 mt-4">
        <label className='p-2'><b>YouTube Link Code</b></label>
          <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Youtube Link Code" name="link"  
          onChange={(e)=>setLink(e.target.value)} />
        </div>
      </div>

      <div className="row justify-content-evenly">
        <div className="col-9 mt-4">
          <label className='p-2'><b>Description</b></label>
          <textarea type="text" className="form-control" autoComplete='off' placeholder="Short Description....." name="description"  
          onChange={(e)=>setDescription(e.target.value)} rows={8} />
        </div>
      </div>
      
      <button type="submit" className={`btn btn-primary col-4 col-md-2 mt-4 p-2 ${disabled ? 'disabled' : null}`} onClick={submit}>Submit</button>
    </div>
  )
}

export default AddProduct

// import React, { useState } from "react";
// import axios from "axios";

// const AddProduct = () => {
//   const [selectedFiles, setSelectedFiles] = useState([]);

//   const handleFileChange = (event) => {
//     setSelectedFiles([...selectedFiles, ...event.target.files]);
//   };

//   const handleSubmit = async (event) => {

//     const formData = new FormData();
//     for (let i = 0; i < selectedFiles.length; i++) {
//       formData.append("images", selectedFiles[i]);
//     }

//     console.log(selectedFiles)

//     try {
//       await axios.post("/api/images", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       alert("Images uploaded successfully");
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (<>
//       <input type="file" name="images" multiple onChange={handleFileChange} />
//       <button type="submit" onClick={handleSubmit}> Upload Images</button>
//   </>
//   );
// };

// export default AddProduct;
